import { COLORS } from 'ui/constants/colors';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  direction?: 'row' | 'column';
  margin?: string | number;
  color?: string;
  lineWidth?: string | number;
  length?: string | number;
}

const Divider = ({
  direction = 'row',
  margin = 16,
  color = COLORS.gray300,
  lineWidth = 1,
  length = '100%',
  style,
  ...props
}: IProps) => {
  const DIVIDER_GROUP = {
    row: (
      <div
        style={{
          width: style?.width ?? typeof length === 'string' ? length : `${length}px`,
          height: style?.height ?? typeof lineWidth === 'string' ? lineWidth : `${lineWidth}px`,
          margin: style?.margin ?? typeof margin === 'string' ? margin : `${margin}px 0`,
          backgroundColor: style?.backgroundColor ?? color,
          ...style,
        }}
        {...props}
      />
    ),
    column: (
      <span
        style={{
          display: 'inline-block',
          width: style?.width ?? typeof lineWidth === 'string' ? lineWidth : `${lineWidth}px`,
          height: style?.height ?? typeof length === 'string' ? length : `${length}px`,
          margin: style?.margin ?? typeof margin === 'string' ? margin : `0 ${margin}px`,
          backgroundColor: style?.backgroundColor ?? color,
          ...style,
        }}
        {...props}
      />
    ),
  };

  return DIVIDER_GROUP[direction];
};

export default Divider;
