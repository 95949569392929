import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { Language, LANGUAGE } from 'ui/constants/language';

export const multiLanguageText = (languageType: Language, kor: string, eng: string) => {
  return languageType === LANGUAGE.KOR ? kor : eng;
};

const useMultiLanguage = () => {
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;
  const languageType = (searchParams.get('languageType') as Language) || LANGUAGE.KOR;

  const _multiLanguageText = (kor: string, eng: string) => multiLanguageText(languageType, kor, eng);

  return { multiLanguageText: _multiLanguageText, languageType };
};

export default useMultiLanguage;
